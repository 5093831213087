import {FETCH_PRINT_INVOICE} from '../../CustomerViewPage/constants'
import axios from 'axios'
import {apiCall} from "../../../common/apiActionHandler";


export const fetchInvoice = (invoiceToken, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/load_invoice`,
        params: {
            payment_modal: true
        }
    };

    apiCall("Fetching invoice", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
        dispatch({type: FETCH_PRINT_INVOICE, invoice: response.data})
    }, dispatch, getState, false);
};


export const loadStripeCredentials = (client_id, callback) => (dispatch, getState) => {


    const config = {
        url: `/api/stripe/stripe_credentials`,
        method: 'GET',
        params: {
            client_id: client_id
        }
    };


    apiCall(null, config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const charge = (token, paymentToken, partialAmount,payWithBalance, newCard, saveCard, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/stripe/charge`,
        method: 'POST',
        data: {
            token: token,
            paymentToken: paymentToken,
            partialAmount: partialAmount,
            payWithBalance: payWithBalance,
            new_card: newCard,
            set_default: saveCard
        }
    };
    apiCall("Creating charge", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const requestRefund = (invoiceToken,charge_id, reason, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/restricted/${invoiceToken}/request_refund`,
        method: 'PUT',
        data: {
            charge_id: charge_id,
            reason: reason
        }
    };
    apiCall("Requesting refund", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const getDefaultPaymentMethod = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_default_payment_method`,
        method: 'GET',
        params: {token: token}
    };

    apiCall("Load customer default payment method", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const loadWalletBalance = (token, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/get_wallet_balance`,
        method: 'GET',
        params: {token: token}
    };

    apiCall("Load customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, null, false);
};

export const updatePaymentSettings = (paymentSettings, customer_id, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/customers/${customer_id}/update_payment_settings`,
        method: 'PUT',
        data: {payment_settings: paymentSettings}
    };

    apiCall("Update customer payment settings", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getManualPaymentTypes = (callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/payment_types/get_manual_payment_types`,
        method: 'GET'
    };

    apiCall("Get manual payment types", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const addPayment = (token, partialAmount, paymentDate, manualPaymentType, manualPaymentReferenceNo, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payments/add`,
        method: 'POST',
        data: {
            token: token,
            partial_amount: partialAmount,
            payment_date: paymentDate,
            payment_type_id: manualPaymentType,
            reference_no: manualPaymentReferenceNo
        }
    };
    apiCall("Creating payment", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const addPaymentType = (name, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/payment_types`,
        method: 'POST',
        data: {
            name
        }
    };
    apiCall("Creating payment type", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const savePaymentMethod = (params, callback) => async (dispatch, getState) => {
    const config = {
        url: `/api/stripe/save_payment_method`,
        method: 'POST',
        data: params
    };

    apiCall("Create customer payment methods", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, null, false);
};