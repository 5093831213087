import React from 'react';
import PropTypes from "prop-types"
import {Circle, GoogleMap, InfoWindow, Marker, Polygon, Polyline, Rectangle,} from 'react-google-maps'
import {getColorIcon, getLabel} from "../IconProvider";
import {Link} from "react-router";
import PolygonModal from "./PolygonModal";
import {checkRectangleCoordinates, colors, defaultMapParams, fitBounds} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import {Button, Col, Row} from "react-bootstrap";
import Attachment from "../../../components/image/Attachment";
import createApiService from "../../../common/clientActionsBuilder";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './MapViewPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpand, faInfoCircle, faLock, faLockOpen, faMapMarkerAlt, faRuler} from "@fortawesome/free-solid-svg-icons";
import PlantName from "../../../components/PlantName";
import {GoogleMapLoader} from "../../../components/GoogleMapLoader";
import MDSpinner from "react-md-spinner";
import _ from 'lodash'
import {getBasicMarker} from "../../../utilities";
import MemoizedMarker from "./MemoizedMarker";
import dot from '../../CrewWorkOrdersPage/dot.png'
import {geolocated} from "react-geolocated";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import {getUserShowLocation, setUserShowLocation} from "./MapActions";


const {DrawingManager} = require("react-google-maps/lib/components/drawing/DrawingManager");
const google = window.google;

const isNotMobile = window.screen.width >= 1024;

const api = createApiService('polygons', 'polygons', 'Polygon');

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

class Map extends ResourceComponent {


    static propTypes = {
        site: PropTypes.object,
        siteAssets: PropTypes.array,
        editable: PropTypes.bool,
        onMarkerEdit: PropTypes.func.isRequired,
        onMarkerAdd: PropTypes.func.isRequired,
        onMarkersAdd: PropTypes.func.isRequired,
        onMarkerClick: PropTypes.func.isRequired,
        onDragend: PropTypes.func.isRequired,
        loaded: PropTypes.object,
        myLocationCheckbox: PropTypes.bool,
    };

    state = {
        distance: null,
        position: null,
        polyLine: null,
        drawingMode: null,
        polygonModal: null,
        polygonClick: null,
        duplicates: [],
        loaded: false,
        onHidePolygonModal: false,
        mapParams: null,
        refresh: true,
        polygonInfoWindow: [],
        isMapLoaded: false,
        showLegend: false,
        isMapView: true,
        refs: [],
        lockedMode: this.props.lockedMode,
        myLocationMarker: null,
        showMyLocation: false,
        locationStatusLoaded: false,
        checkboxLoading: false,
    };


    shouldReload = (nextProps) => {
        return this.props.loaded && nextProps.loaded &&
            this.props.loaded.valueOf() !== nextProps.loaded.valueOf();
    };

    isCompleted = (asset) => {
        return !!asset.completed_at
    }

    componentWillReceiveProps = (nextProps) => {
        const {siteAssets, site, proposalPolygon} = this.props;
        const firstAssetAdded = site && nextProps.site
            && site.id === nextProps.site.id
            && siteAssets && nextProps.siteAssets
            && siteAssets.length === 0 && nextProps.siteAssets.length > 0;
        const siteHasChanged = site && nextProps.site && site.id !== nextProps.site.id;
        if (this.props.newAsset) {
            const asset = siteAssets.find((a) => a.id === this.props.newAsset.id)
            const index = siteAssets.indexOf(asset)
            siteAssets[index] = this.props.newAsset
            this.setState({siteAssets: siteAssets});
        }
        if (this.props.opacity) {
            const site = siteAssets.find((val) => val.id === this.props.opacity.id)
            const index = siteAssets.indexOf(site)
            siteAssets[index].opacity = this.props.opacity.val
            this.setState({siteAssets: siteAssets});
        }
        if (firstAssetAdded || siteHasChanged || this.shouldReload(nextProps)) {
            this.setState({loaded: false, proposalPolygon}, this.reloadMapParams);
        }
        if (!_.isEqual(siteAssets, nextProps.siteAssets) && nextProps.siteAssets.length === 0) {
            this.setState({siteAssets: siteAssets, isMapLoaded: false});
        }
        if (!_.isEqual(nextProps.siteAssets?.map(a => a.id)?.sort(), siteAssets?.map(a => a.id)?.sort())) {
            this.reloadMapParams(undefined, false)
        }
        if (!this.props.coords && nextProps.coords && !this.state.myLocationMarker) {
            this.setState({
                myLocationMarker: {
                    latitude: nextProps.coords.latitude,
                    longitude: nextProps.coords.longitude
                }
            })
        }
        if (!this.state.locationStatusLoaded) {
            this.props.actions.getUserShowLocation(() => {
                this.setState({locationStatusLoaded: true})
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fullScreen, siteAssets} = this.props;
        const {isMapView} = this.state;

        if (prevProps.fullScreen !== fullScreen && fullScreen) {
            this.reloadMapParams()
        } else if (prevProps.fullScreen !== fullScreen && !fullScreen) {
            this.reloadMapParams()
        }

        if ((prevState.isMapView !== isMapView) && isMapView) {
            this.props.setStreetView(!isMapView)
        }
        if (!isMapView && this.state.mode === 'duplication') {
            this.finishDuplication()
        }

        if (prevProps.lockedMode !== this.props.lockedMode) {
            this.setState({lockedMode: this.props.lockedMode})
        }
        if (prevProps.active !== this.props.active) {
            this.reloadMapParams()
        }
        if (prevState.showMyLocation !== this.state.showMyLocation) {
            this.reloadMapParams()
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.duplicates && (this.state.duplicates.length !== nextState.duplicates.length) || this.state.polyLine !== nextState.polyLine) return true
        let copyOfNextState = {...nextState, refs: []}
        let copyOfState = {...this.state, refs: []}

        const diffState = _.differenceWith(_.toPairs(copyOfNextState), _.toPairs(copyOfState), _.isEqual)
        const diffAsset = _.differenceWith(_.toPairs(nextProps.siteAssets), _.toPairs(this.props.siteAssets), _.isEqual)

        return !!(diffState && diffState.length > 0 || diffAsset && diffAsset.length > 0 || nextProps.lockedMode !== this.props.lockedMode);
    }

    getZIndex = asset => {
        if (asset.__highlighted === true || asset.__locked === true) {
            //we injected this property to modify state on the next rerender - its slow. maybe we shoudl instead use setState?
            return this.props.siteAssets.length + 10
        } else {
            return this.props.siteAssets.findIndex(a => a.id === asset.id) + 1
        }

    };


    componentDidMount() {
        this.reloadMapParams();
    }

    detectClick = (number) => {
        return document.querySelector(`#leadMap > div:nth-child(2) > div > div:nth-child(4) > div > div:nth-child(${number})`)
    }

    handleMarkerType = (i) => {
        let result = null
        switch (i) {
            case 2:
                result = 'marker'
                break;
            case 3:
                result = google.maps.drawing.OverlayType.POLYLINE
                break;
            case 4:
                result = google.maps.drawing.OverlayType.POLYGON
                break;
            case 5:
                result = google.maps.drawing.OverlayType.RECTANGLE
                break;
            case 6:
                result = google.maps.drawing.OverlayType.CIRCLE
                break;
            default:
                result = null
        }
        return result
    }

    toggleInfoWindow = () => {
        this.state.polyLine.setMap(null);
        this.setState({distance: null, polyLine: null})
    };
    onMeasureComplete = polyLine => {
        if (this.state.mode === 'duplication') {
            this.finishDuplication()
        }
        let path = polyLine.getPath();
        let totalDistance = google.maps.geometry.spherical.computeLength(path)
        totalDistance *= 3.28084;
        this.setState({
            distance: totalDistance.toFixed(2).toString() + ' ft.',
            position: {
                lat: path.getArray()[0].lat(),
                lng: path.getArray()[0].lng(),
            },
            polyLine: polyLine,
        })
    };

    onPolyLineComplete = polyLine => {
        if (this.state.mode === 'duplication') {
            this.finishDuplication()
        }
        let path = polyLine.getPath();

        const drawValue = path.getArray().map((p) => ({
            latitude: p.lat(),
            longitude: p.lng(),
            id: null,
        }))
        const resource = {
            color: 1,
            type_polygon: 'polyLine',
            polygon_points_attributes: drawValue
        }

        let selectedAssetVal = null
        selectedAssetVal = this.getAssetValues('polyLine', polyLine)
        this.setState({selectedAssetVal: selectedAssetVal});
        this.props.onPolygonAdd({
            latitude: path.getArray()[0].lat(),
            longitude: path.getArray()[0].lng(),
            polygons: resource
        }, selectedAssetVal);

        polyLine.setMap(null)
    };
    drawingSave = (draw) => {
        if (this.state.mode === 'duplication') {
            this.finishDuplication()
        }
        const path = draw.getPath();

        if (path.getLength() < 3) {
            draw.setMap(null);
        } else {
            const drawValue = path.getArray().map((p) => ({
                latitude: p.lat(),
                longitude: p.lng(),
                id: null,
            }))
            let selectedAssetVal = null
            selectedAssetVal = this.getAssetValues('polygon', draw)
            this.setState({selectedAssetVal: selectedAssetVal});
            const resource = {
                type_polygon: 'polygon',
                polygon_points_attributes: drawValue
            }
            this.props.onPolygonAdd({
                ...drawValue[0],
                polygons: resource
            }, selectedAssetVal);
            draw.setMap(null)
        }
    }
    drawCircle = (draw) => {
        if (this.state.mode === 'duplication') {
            this.finishDuplication()
        }
        const resource = {
            type_polygon: 'circle',
            radius: draw.radius,
            polygon_points_attributes: [{
                latitude: draw.center.lat(),
                longitude: draw.center.lng(),
            }]
        }
        let selectedAssetVal = null
        selectedAssetVal = this.getAssetValues('circle', draw)
        this.setState({selectedAssetVal: selectedAssetVal});
        this.props.onPolygonAdd({
            latitude: draw.center.lat(),
            longitude: draw.center.lng(),
            polygons: resource,
        }, selectedAssetVal);
        draw.setMap(null)
    }
    drawRectangle = (draw) => {
        if (this.state.mode === 'duplication') {
            this.finishDuplication()
        }
        const path = draw.getBounds();
        let lat = Object.values(path)[0]
        let long = Object.values(path)[1]
        const polygon_points_attributes = [
            {latitude: lat && lat[Object.keys(lat)[0]], longitude: long && long[Object.keys(long)[0]]},
            {latitude: lat && lat[Object.keys(lat)[0]], longitude: long && long[Object.keys(long)[1]]},
            {latitude: lat && lat[Object.keys(lat)[1]], longitude: long && long[Object.keys(long)[1]]},
            {latitude: lat && lat[Object.keys(lat)[1]], longitude: long && long[Object.keys(long)[0]]}
        ]
        let selectedAssetVal = null
        selectedAssetVal = this.getAssetValues('rectangle', draw)
        this.setState({selectedAssetVal: selectedAssetVal});
        const resource = {
            label: 'New layer',
            type_polygon: 'rectangle',
            polygon_points_attributes: polygon_points_attributes
        }
        this.props.onPolygonAdd({
            ...polygon_points_attributes[0],
            polygons: resource
        }, selectedAssetVal);
        draw.setMap(null)
    }

    refresh = () => {
        this.setState({refresh: true})
    }

    deletePolygon = (resource) => {
        const filterPolygon = this.state.proposalPolygon.site.filter((polygon) => polygon.id !== resource.id)
        this.setState({proposalPolygon: {site: filterPolygon}, polygonClick: null})
        this.props.actions.remove(resource)
    }
    getSelectedAssets = () => {
        const {siteAssets} = this.props;
        return siteAssets.filter(a => a.selected);
    };

    finishDuplication = (callback) => {
        const duplicates = this.state.duplicates;
        this.setState({duplicates: [], editable: false, drawingMode: null, mode: null, selectedAsset: null}, () => {
            duplicates.forEach(x => {
                google.maps.event.clearInstanceListeners(x);
                x.setMap(null);
            });
            callback && callback(duplicates, this.getSelectedAssets()[0].id);
        })
    };

    onDragend = (ass) => (e) => {
        if (this.state.mode === 'duplication') {
            this.finishDuplication()
        }
        this.setState({drawingMode: null});
        this.props.onDragend(ass.id, e);
    };

    onCircle = (ass, val) => (e) => {
        const polygon = ass.polygons.find(p => p.chosen)
        val = this.state.refs[val]
        const center = val.getCenter()
        const polygon_points_attributes = [{
            id: polygon.polygon_points[0].id,
            latitude: center.lat(),
            lat: center.lat(),
            longitude: center.lng(),
            lng: center.lng(),
        }]
        this.props.onDragendCircle(ass.id, center, polygon_points_attributes);
    };

    onCircleEdit = (ass, i) => {
        let ref = this.state.refs[i]
        let selectedAssetVal = this.getAssetValues('circle', ref)
        this.props.onCircleEdit(selectedAssetVal, ass.id, ref.getRadius())
        this.setState({selectedAssetVal: selectedAssetVal});
    };

    onChange = (ass, i, asset_type = null) => {
        let e = this.state.refs[i]
        const polygon = ass.polygons.find(p => p.chosen)
        const valueOld = polygon.polygon_points;
        if (asset_type === null) {
            asset_type = polygon.type_polygon
        }
        let selectedAssetVal = this.getAssetValues(asset_type, e)
        if (!e.getPath) { //if rectangle
            const path = e.getBounds();
            let lat = Object.values(path)[0]
            let long = Object.values(path)[1]
            const polygon_points_attributes = [
                {
                    latitude: lat && lat[Object.keys(lat)[1]],
                    lat: lat && lat[Object.keys(lat)[1]],
                    longitude: long && long[Object.keys(long)[0]],
                    lng: long && long[Object.keys(long)[0]],
                    id: valueOld[3].id
                },
                {
                    latitude: lat && lat[Object.keys(lat)[1]],
                    lat: lat && lat[Object.keys(lat)[1]],
                    longitude: long && long[Object.keys(long)[1]],
                    lng: long && long[Object.keys(long)[1]],
                    id: valueOld[2].id
                },
                {
                    latitude: lat && lat[Object.keys(lat)[0]],
                    lat: lat && lat[Object.keys(lat)[0]],
                    longitude: long && long[Object.keys(long)[1]],
                    lng: long && long[Object.keys(long)[1]],
                    id: valueOld[1].id
                },
                {
                    latitude: lat && lat[Object.keys(lat)[0]],
                    lat: lat && lat[Object.keys(lat)[0]],
                    longitude: long && long[Object.keys(long)[0]],
                    lng: long && long[Object.keys(long)[0]],
                    id: valueOld[0].id
                }
            ]

            let anyMoved = false
            polygon_points_attributes.forEach((currentValue, i) => {
                if (!valueOld[i] || currentValue.latitude !== valueOld[i].lat || currentValue.longitude !== valueOld[i].lng) {
                    anyMoved = true
                }
            })
            if (anyMoved) {
                this.props.onDragendPoly(selectedAssetVal, ass.id, polygon_points_attributes);
            }
        } else {
            const value = e.getPath().getArray().map((p, i) => ({
                latitude: p.lat(),
                longitude: p.lng(),
                lat: p.lat(),
                lng: p.lng(),
                id: polygon.polygon_points[i] && polygon.polygon_points[i].id,
            }))
            let anyMoved = false
            value.forEach((currentValue, i) => {
                if (!valueOld[i] || currentValue.latitude !== valueOld[i].lat || currentValue.longitude !== valueOld[i].lng) {
                    anyMoved = true
                }
            })
            if (anyMoved) {
                if (polygon.type_polygon === 'rectangle') {
                    this.props.onDragendPoly(selectedAssetVal, ass.id, value, 'polygon');
                } else {
                    this.props.onDragendPoly(selectedAssetVal, ass.id, value);
                }
            }
        }

        this.setState({selectedAssetVal: selectedAssetVal});

    };

    sqft = (val) => {
        val *= 10.7639104;
        return val.toFixed(2)
    }

    ft = (val) => {
        val *= 3.28084;
        return val.toFixed(2)
    }
    onMarkerClick = (ass, i, type) => {
        let e = (i >= 0 ? this.state.refs[i] : null);
        let selectedAssetVal = null
        this.setState({
            mode: this.state.duplicates.length ? 'duplication' : this.state.mode,
            drawingMode: this.state.duplicates.length ? 'marker' : null,
            selectedAsset: null,
            selectedAssetVal: this.state.duplicates.length ? this.state.selectedAssetVal : null
        });
        selectedAssetVal = this.getAssetValues(type, e)
        this.setState({
            mode: this.state.duplicates.length ? 'duplication' : this.state.mode,
            drawingMode: this.state.duplicates.length ? 'marker' : null,
            selectedAsset: ass.id,
            selectedAssetVal: selectedAssetVal
        });
        this.props.onMarkerClick(ass.id, selectedAssetVal);
    };

    onSteetViewChange = (siteAssets) => {
        siteAssets.forEach(asset => {
            if (asset.polygons?.length > 0) {
                const polygon = asset.polygons.find(p => p.chosen)
                const center = {}

                const coords = polygon.polygon_points.map(({lat, lng}) => ({lat, lng}))

                const xValues = coords.map(({lat}) => lat)
                const yValues = coords.map(({lng}) => lng)

                const x1 = Math.min.apply(Math, xValues)
                const x2 = Math.max.apply(Math, xValues)

                const y1 = Math.min.apply(Math, yValues)
                const y2 = Math.max.apply(Math, yValues)

                center.lat = x1 + ((x2 - x1) / 2);
                center.lng = y1 + ((y2 - y1) / 2);

                asset.lat = center.lat
                asset.lng = center.lng

                asset.opacity = false
            } else {
                asset.lat = asset.latitude
                asset.lng = asset.longitude
            }
        })
    }

    reloadMapParams = (callback, centerMap = true) => {
        const {
            site,
            siteAssets,
            client,
        } = this.props;

        let coordinates = [];
        siteAssets.forEach(asset => {
            if (asset.polygons && asset.polygons.length > 0) {
                const polygon = asset.polygons.find(p => p.chosen)
                for (let p of polygon.polygon_points) {
                    coordinates.push({latitude: p.lat, longitude: p.lng});
                }
            } else if (asset.polygons && asset.polygons.length === 0 && asset.latitude && asset.longitude) {
                asset.lat = asset.latitude
                asset.lng = asset.longitude
                coordinates.push({latitude: asset.lat, longitude: asset.lng});
            }
        })
        const {center, myLocationMarker, showMyLocation} = this.state;
        const mapParams = defaultMapParams(siteAssets, center, null, client);
        if (centerMap) {
            if (site && coordinates.length === 0 && !showMyLocation) {
                mapParams.center = {lat: site.latitude, lng: site.longitude};
                mapParams.zoom = 18;
            } else {
                if (this.map) {
                    if (showMyLocation && myLocationMarker) {
                        setTimeout(() => fitBounds(this.map, [...coordinates, {
                            latitude: myLocationMarker.latitude,
                            longitude: myLocationMarker.longitude
                        }]), 0);
                    } else {
                        setTimeout(() =>
                            fitBounds(this.map, coordinates)
                        , 0);
                    }
                    this.setState({isMapLoaded: true})
                }
            }
        }
        this.setState({mapParams}, callback);

    };

    onHidePolygonModal = (val) => {
        this.setState({onHidePolygonModal: !this.state.onHidePolygonModal, polygonModal: val})
    }

    infoWindow = (ass, lat, lng) => {
        const {mode} = this.state;
        const {onMarkerEdit, onMarkersAdd, anyMoved} = this.props;
        const selectedAssets = this.getSelectedAssets().map(x => x.id);

        if (selectedAssets.includes(ass.id) && this.state.selectedAsset !== null) {
            if (ass.image_order) {
                let order = JSON.parse(ass.image_order);
                ass.images = ass.images.map(img => {
                    img.order = order.findIndex((o) => o === img.id)
                    return img
                })
            } else {
                ass.images = ass.images.map((img, index) => {
                    img.order = index
                    return img
                })
            }
            return (
                <InfoWindow
                    key={this.state.selectedAsset}
                    position={{lat: lat ? lat : ass.latitude, lng: lng ? lng : ass.longitude}}
                    onCloseclick={() => this.setState({selectedAsset: null})}>
                    <div className="info-window no_white-space">
                        {mode === 'duplication' &&
                            <div>Creating duplicates of: <br/>
                                <b><PlantName plant={ass.plant_name}/></b><br/>
                                <Link className="small-padding pointer" style={{color: '#1a664f'}}
                                      onClick={() => {
                                          this.finishDuplication((duplicates, selectedAsset) => {
                                              onMarkersAdd(selectedAsset, duplicates);
                                          });
                                      }}>
                                    Save
                                </Link>
                                <Link className="small-padding pointer" style={{color: 'red'}}
                                      onClick={() => {
                                          this.finishDuplication();
                                      }}>
                                    Cancel
                                </Link>
                            </div>}
                        {mode !== 'duplication' &&
                            <div>
                                <Row>

                                    {ass.images.filter(p => p.cwo_and_proposal_display === true).length > 0 &&
                                        <Col md={4}>
                                            {ass.images.filter(p => p.cwo_and_proposal_display === true).sort((a, b) => a.order - b.order).slice(0, 1).map(i =>
                                                <Attachment key={i.id}
                                                            image={i}
                                                />)}
                                        </Col>}
                                    <Col md={8}>
                                        <b>#{ass.label}{" "}<PlantName plant={ass.plant_name}/></b><br/>
                                        {ass.plant_dbh ? `DBH: ${ass.plant_dbh}"` : ""}
                                        {ass.plant_dbh ? <br/> : null}
                                        <Link disabled={anyMoved} className="small-padding pointer"
                                              style={{color: anyMoved ? 'gray' : 'red'}}
                                              onClick={() => {
                                                  onMarkerEdit(ass, this.state.selectedAssetVal);
                                              }}>
                                            Edit
                                        </Link>
                                        {(ass.polygons.length > 0 && !this.state.lockedMode) ? mode === 'resize' ?
                                            <Link className="small-padding pointer" style={{color: 'red'}}
                                                  onClick={() => {
                                                      this.setState({
                                                          editable: false,
                                                          mode: null
                                                      })
                                                  }}
                                            >
                                                Stop resizing
                                            </Link>
                                            :
                                            <Link className="small-padding pointer" style={{color: 'red'}}
                                                  onClick={() => {
                                                      this.setState({
                                                          editable: true,
                                                          drawingMode: null,
                                                          mode: 'resize'
                                                      })
                                                  }}
                                            >
                                                Modify
                                            </Link> : null
                                        }

                                        <Link className="small-padding pointer" style={{color: 'red'}}
                                              onClick={() => {
                                                  this.setState({
                                                      editable: true,
                                                      drawingMode: 'marker',
                                                      mode: 'duplication'
                                                  })
                                              }
                                              }
                                        >
                                            Duplicate
                                        </Link>
                                    </Col>
                                </Row>
                            </div>}
                    </div>
                </InfoWindow>
            )
        }
    }


    markerPolygon = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)
        const points = polygon.polygon_points
        let averageLat = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lat) {
                if (i === 1) {
                    return previousValue.lat + currentValue.lat
                }
                return previousValue + currentValue.lat
            } else {
                if (i === 1) {
                    return previousValue.latitude + currentValue.latitude
                }
                return previousValue + currentValue.latitude
            }
        }) / points.length : ass.latitude
        let averageLng = (polygon.type_polygon !== 'circle' && polygon.type_polygon !== 'polyLine')
            ? points.reduce((previousValue, currentValue, i) => {
            if (currentValue.lng) {
                if (i === 1) {
                    return previousValue.lng + currentValue.lng
                }
                return previousValue + currentValue.lng
            } else {
                if (i === 1) {
                    return previousValue.longitude + currentValue.longitude
                }
                return previousValue + currentValue.longitude
            }
        }) / points.length : ass.longitude
        if (polygon.type_polygon === 'polyLine') {
            const index = Math.round(points.length / 2) - 1
            if (points.length % 2 !== 0) {
                averageLat = points[index].lat ? points[index].lat : points[index].latitude
                averageLng = points[index].lng ? points[index].lng : points[index].longitude
            } else {
                if (points[0].lat) {
                    averageLat = (points[index].lat + points[index + 1].lat) / 2
                    averageLng = (points[index].lng + points[index + 1].lng) / 2
                } else {
                    averageLat = (points[index].latitude + points[index + 1].latitude) / 2
                    averageLng = (points[index].longitude + points[index + 1].longitude) / 2
                }
            }

        }
        return (
            <Marker
                position={{lat: averageLat, lng: averageLng}}
                icon={getLabel(ass.label)}
                strokeColor={"#ffffff"}
                onClick={() => this.onMarkerClick(ass)}
                zIndex={1000}
            >
                {this.infoWindow(ass, averageLat, averageLng)}
            </Marker>
        )
    }

    setShapeOpacity = (ass) => {
        const polygon = ass.polygons.find(p => p.chosen)

        if (ass.shapeOpacity && polygon.type_polygon === 'polyLine') {
            return 0.35
        } else if (ass.shapeOpacity) {
            return 0.2
        } else if (ass.opacity && polygon.type_polygon === 'polyLine') {
            return 0.75
        } else if (ass.opacity) {
            return 0.5
        } else {
            return 1
        }
    }

    onGetLocationSuccess(position) {
        this.props.onGeolocationMarkerAdd(position.coords);
    }


    onGetLocationError(err) {
        alert(err.message)
    }


    render() {
        const {
            site,
            siteAssets,
            onMarkerAdd,
            fullScreen,
            anyMoved,
            asset,
            showLabels,
            showNewAssets
        } = this.props;
        let {
            drawingMode, editable, mode, duplicates, center, mapParams, refresh, isMapLoaded, isMapView,
            showMyLocation,
            myLocationMarker
        } = this.state;
        editable = editable || this.props.editable;
        if (site === undefined) {
            return <span>Map appears when a Site is selected</span>
        }
        const isDesktop = window.screen.width >= 992;
        //DEPRECATED
        if (center) {
            this.setState({center: false});
        }

        if (mapParams && site && siteAssets?.length > 0) {
            mapParams.streetViewControl = true
            if (this.map) {
                const panorama = this.map.getStreetView()

                google.maps.event.addListener(panorama, 'visible_changed', () => {
                    if (panorama.getVisible()) {
                        this.setState({isMapView: false})
                    } else {
                        this.setState({isMapView: true})
                    }
                })
            }
        }

        return (
            mapParams && site ? <div><GoogleMapLoader
                containerElement={
                    <div
                        id="leadMap"
                        className={fullScreen && !isDesktop ?
                            'full-map lead-map' : 'lead-map'
                        }
                        style={{
                            height: "83vh",
                            width: "100%"
                        }}
                    />
                }
                googleMapElement={
                    <GoogleMap
                        {...mapParams}
                        options={{
                            fullscreenControl: false
                        }}
                        onIdle={() => {
                            if (!isMapLoaded) {
                                this.reloadMapParams()
                            }
                            if (site && siteAssets && siteAssets.length > 0 && !isMapLoaded) {
                                this.setState({isMapLoaded: true})
                            }
                        }}
                        ref={it => {
                            this.map = it;
                        }}
                    >
                        {editable &&
                            <DrawingManager
                                drawingMode={drawingMode}
                                defaultOptions={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        position: google.maps.ControlPosition.TOP_CENTER,
                                        drawingModes: [
                                            'marker',
                                            'SearchBox',
                                            google.maps.drawing.OverlayType.POLYLINE,
                                            google.maps.drawing.OverlayType.POLYGON,
                                            google.maps.drawing.OverlayType.RECTANGLE,
                                            google.maps.drawing.OverlayType.CIRCLE

                                        ],
                                    },

                                    polylineOptions: {strokeColor: '#ff0000'},
                                    polygonOptions: {strokeColor: '#0000ff'},
                                    rectangleOptions: {strokeColor: '#ff0000'},
                                    circleOptions: {strokeColor: '#ff0000'}
                                }}
                                onMarkerComplete={marker => {
                                    if (mode === 'duplication') {
                                        duplicates.push(marker);
                                    } else {
                                        marker.setMap(null);
                                        this.setState({drawingMode: 'marker'});
                                        onMarkerAdd(marker);
                                    }
                                }}
                                onPolylineComplete={mode === 'measuring' ? this.onMeasureComplete : this.onPolyLineComplete}
                                onPolygonComplete={this.drawingSave}
                                onCircleComplete={this.drawCircle}
                                onRectangleComplete={this.drawRectangle}
                            />
                        }
                        {isMapLoaded && <Button bsStyle={'light'}
                                                className={`${fullScreen && isNotMobile ? 'mr-20' : 'mr-20'} btn-full-screen`}
                                                onClick={() => {
                                                    if (this.state.mode === 'duplication') {
                                                        this.finishDuplication()
                                                    }
                                                    this.props.onToggleFullScreen()
                                                }}>
                            <FontAwesomeIcon size={8} icon={faExpand}/></Button>}
                        {!anyMoved && isMapLoaded &&
                            <Button bsStyle={mode === 'measuring' ? "primary" : 'light'}
                                    className={`${fullScreen && isNotMobile && 'mr-10'} btn-measure`}
                                    onClick={() => {
                                        if (this.state.mode === 'duplication') {
                                            this.finishDuplication()
                                        }
                                        this.setState({
                                                mode: mode === 'measuring' ? null : 'measuring',
                                                drawingMode: google.maps.drawing.OverlayType.POLYLINE
                                            }, () => {

                                                for (let i = 1; i <= 6; i++) {
                                                    let dm = this.detectClick(i)
                                                    if (dm) {
                                                        this.state.mode === 'measuring' ? dm.addEventListener('click', () => this.setState({
                                                            mode: null,
                                                            drawingMode: this.handleMarkerType(i)
                                                        })) : dm.removeEventListener('click', () => this.setState({
                                                            mode: null,
                                                            drawingMode: this.handleMarkerType(i)
                                                        }))
                                                    }

                                                }
                                            }
                                        )
                                    }}>
                                <FontAwesomeIcon icon={faRuler}/></Button>}
                        {isMapLoaded && <>{!this.state.lockedMode ? <Button bsStyle={'light'}
                                                                            className={`${fullScreen && isNotMobile ? 'mr-20' : 'mr-20'} btn-lock`}
                                                                            onClick={() => {
                                                                                if (this.state.mode === 'duplication') {
                                                                                    this.finishDuplication()
                                                                                }
                                                                                this.setState({
                                                                                        lockedMode: !this.state.lockedMode,
                                                                                        editable: false,
                                                                                        mode: 'null'
                                                                                    },
                                                                                    this.props.onToggleLock)
                                                                            }}>
                                <FontAwesomeIcon size={8} icon={faLockOpen}/></Button>
                            : <Button bsStyle={'light'}
                                      className={`${fullScreen && isNotMobile ? 'mr-20' : 'mr-20'} btn-lock`}
                                      onClick={() => {
                                          if (this.state.mode === 'duplication') {
                                              this.finishDuplication()
                                          }
                                          this.setState({lockedMode: !this.state.lockedMode},
                                              this.props.onToggleLock)
                                      }}>
                                <FontAwesomeIcon size={8} icon={faLock}/></Button>}</>}
                        {isMobile && isMapLoaded && <Button bsStyle={'light'}
                                                            className={`${fullScreen && isNotMobile ? 'mr-20' : 'mr-20'} btn-add-marker ${showNewAssets ? 'new-assets' : ''}`}
                                                            onClick={() => {
                                                                if (!navigator.geolocation) {
                                                                    alert(`Your browser doesn't support Geolocation`)
                                                                } else {
                                                                    navigator.geolocation.getCurrentPosition((position) => this.onGetLocationSuccess(position), (err) => this.onGetLocationError(err));
                                                                }
                                                            }}>
                            <FontAwesomeIcon size={8} icon={faMapMarkerAlt}/></Button>}
                        {showNewAssets && <Button bsStyle={'light'}
                                                  className={`${fullScreen && isNotMobile ? 'mr-20' : 'mr-20'} legend-btn`}
                                                  onClick={() => {
                                                      this.setState({showLegend: !this.state.showLegend})
                                                  }}>
                            <FontAwesomeIcon icon={faInfoCircle}/></Button>}

                        {this.state.showLegend && showNewAssets && <ul className='legend'>
                            <b>Legend</b>
                            <li><img src={getColorIcon('#f8e500', false, 1, false, false).url} height="35"/>
                                <p>Not completed</p>
                            </li>
                            <li><img src={getColorIcon('#f8e500', false, 1, true, false).url} height="35"/>
                                <p>Completed this year</p>
                            </li>
                            <li><img src={getColorIcon('#f8e500', false, 1, true, true).url} height="35"/>
                                <p>Completed in previous years</p>
                            </li>
                        </ul>}
                        {this.state.distance
                            ? <InfoWindow
                                onCloseClick={this.toggleInfoWindow}
                                position={this.state.position}
                            >
                                <div>
                                    {this.state.distance}
                                </div>
                            </InfoWindow>
                            : null}

                        {refresh && siteAssets.map((ass, i) => {
                            const polygon = ass.polygons.find(p => p.chosen)
                            const selectedAssets = this.getSelectedAssets().map(x => x.id);

                            if (!isMapView) {
                                this.onSteetViewChange(siteAssets)
                            }

                            if (polygon && polygon.type_polygon !== 'marker' && isMapView) {
                                const color = polygon.color ? colors[polygon.color] : colors[parseInt(ass.plant_color)]
                                const opacity = this.setShapeOpacity(ass)

                                if (polygon.type_polygon === 'circle') {
                                    return (<Circle
                                        ref={(ref) => {
                                            let refs = this.state.refs
                                            refs[i] = ref
                                            this.setState({refs: refs})
                                        }}
                                        defaultOpacity={1}
                                        onClick={(e) => this.onMarkerClick(ass, i, 'circle')}
                                        defaultCenter={{
                                            lat: polygon.polygon_points[0].lat,
                                            lng: polygon.polygon_points[0].lng
                                        }}
                                        editable={mode === 'resize' && selectedAssets.includes(ass.id) && this.state.selectedAsset !== null && !this.state.lockedMode}
                                        draggable={!this.state.lockedMode}
                                        options={{
                                            fillOpacity: opacity,
                                            strokeColor: color,
                                            fillColor: color
                                        }}
                                        radius={polygon.radius}
                                        key={i}
                                        onDragEnd={this.onCircle(ass, i)}
                                        onCenterChanged={this.onCircle(ass, i)}
                                        onRadiusChanged={() => this.onCircleEdit(ass, i)}
                                    />)
                                } else if (polygon.type_polygon === 'polyLine') {
                                    return (<Polyline
                                        ref={(ref) => {
                                            let refs = this.state.refs
                                            refs[i] = ref
                                            this.setState({refs: refs})
                                        }}
                                        onMouseUp={() => this.onChange(ass, i)}

                                        onClick={(e) => this.onMarkerClick(ass, i, 'polyLine')}
                                        path={polygon.polygon_points}
                                        key={i}
                                        options={{
                                            strokeOpacity: opacity,
                                            strokeColor: color,
                                            fillColor: color
                                        }}
                                        editable={mode === 'resize' && selectedAssets.includes(ass.id) && this.state.selectedAsset !== null && !this.state.lockedMode}
                                        draggable={!this.state.lockedMode}
                                    />)
                                } else if (polygon.type_polygon === 'rectangle' && checkRectangleCoordinates(polygon)) {
                                    return (<Rectangle
                                        ref={(ref) => {
                                            let refs = this.state.refs
                                            refs[i] = ref
                                            this.setState({refs: refs})
                                        }}
                                        onMouseUp={() => this.onChange(ass, i)}

                                        onClick={(e) => {
                                            this.onMarkerClick(ass, i, 'rectangle')
                                        }}
                                        bounds={{
                                            north: polygon.polygon_points[0].lat || polygon.polygon_points[0].latitude,
                                            south: polygon.polygon_points[3].lat || polygon.polygon_points[3].latitude,
                                            east: polygon.polygon_points[1].lng || polygon.polygon_points[1].longitude,
                                            west: polygon.polygon_points[0].lng || polygon.polygon_points[0].longitude,
                                        }}
                                        key={i}
                                        onBoundsChanged={() => this.onChange(ass, i)}
                                        options={{
                                            fillOpacity: opacity,
                                            strokeColor: color,
                                            fillColor: color
                                        }}
                                        editable={mode === 'resize' && selectedAssets.includes(ass.id) && this.state.selectedAsset !== null && !this.state.lockedMode}
                                        draggable={!this.state.lockedMode}
                                    />)
                                } else {
                                    return (<Polygon
                                        ref={(ref) => {
                                            let refs = this.state.refs
                                            refs[i] = ref
                                            this.setState({refs: refs})
                                        }}
                                        onMouseUp={() => this.onChange(ass, i)}

                                        onClick={(e) => this.onMarkerClick(ass, i, 'polygon')}
                                        path={polygon.polygon_points}
                                        key={i}
                                        options={{
                                            fillOpacity: opacity,
                                            strokeColor: color,
                                            fillColor: color
                                        }}
                                        editable={mode === 'resize' && selectedAssets.includes(ass.id) && this.state.selectedAsset !== null && !this.state.lockedMode}
                                        draggable={!this.state.lockedMode}
                                    />)
                                }
                            } else {
                                if (!isMapView) {
                                    ass.latitude = ass.lat
                                    ass.longitude = ass.lng
                                    if (ass.polygons?.length > 0) {
                                        ass.plant_color = polygon.color ? polygon.color : ass.plant_color
                                    }
                                    ass.polygons = []
                                }

                                return <MemoizedMarker
                                    isSelected={selectedAssets.includes(ass.id) && this.state.selectedAsset !== null}
                                    duplicating={selectedAssets.includes(ass.id) && this.state.selectedAsset !== null && mode === "duplication"}
                                    index={i}
                                    key={ass.id}
                                    editable={editable}
                                    ass={ass}
                                    showNewAssets={showNewAssets}
                                    lockedMode={this.state.lockedMode}
                                    refs={this.state.refs}
                                    mode={this.state.mode}
                                    setRefs={r => this.setState({refs: r})}
                                    infoWindow={this.infoWindow}
                                    onDragend={m => this.onDragend(m)}
                                    onMarkerClick={m => this.onMarkerClick(m)}
                                />
                            }
                        })}
                        {refresh && siteAssets.map((ass) => {
                            const polygon = ass.polygons.find(p => p.chosen)
                            if (polygon && polygon.type_polygon !== 'marker') {
                                return (
                                    this.markerPolygon(ass)
                                )
                            }
                        })}
                        {site && siteAssets.length === 0 &&
                            <Marker
                                key={1}
                                position={{lat: site.latitude, lng: site.longitude}}
                                icon={getBasicMarker({selected: false, color: "#ff6479", label: "x"})}
                                title={site.name}
                            />}
                        {showMyLocation && myLocationMarker &&
                            <Marker
                                position={{lat: myLocationMarker.latitude, lng: myLocationMarker.longitude}}
                                icon={dot}
                                title={"Here you are!"}
                            />}
                        <PolygonModal
                            show={this.state.onHidePolygonModal}
                            onHide={this.onHidePolygonModal}
                            resource={this.state.polygonModal}
                            destoryPolygon={(polygon) => {
                                this.props.actions.remove(polygon)
                                this.deletePolygon(polygon)
                            }}
                            savePolygon={(resource) => this.props.actions.save(resource)}
                        />
                    </GoogleMap>
                }
            />
                {this.props.myLocationCheckbox && <ColorCheckbox value={this.state.showMyLocation}
                                                                 onChange={(value) => {
                                                                     this.setState({checkboxLoading: true})
                                                                     this.props.actions.setUserShowLocation(value, () => {
                                                                         this.setState({
                                                                             showMyLocation: value,
                                                                             checkboxLoading: false
                                                                         })
                                                                     })
                                                                 }}
                                                                 disabled={this.state.checkboxLoading}
                                                                 label={"My Location"}
                />}
            </div> : <div
                className={fullScreen && !isDesktop ?
                    'full-map lead-map' : 'lead-map margin10'
                }
                style={{
                    textAlign: "center",
                    height: "83vh",
                    width: "97%",
                }}
            >
                <MDSpinner className="top-50" size={60}/>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        client: state.client.customerInfo
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({...api, getUserShowLocation, setUserShowLocation}, dispatch),
});

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(Map))
