import axios from "axios/index";
import {apiCall} from "../../common/apiActionHandler";
import {mapForSelect, optionsFor} from "../../common/commonHandlers";
import {TOGGLE_SCHEDULER_VIEW} from "../LoginPage/constants";

export const loadEventsData = (filter, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/holidays`, params: filter},
        {url: `/api/scheduler_events/birthdays`, params: filter},
        {url: `/api/scheduler_events`, params: filter},
        {url: `/api/search/all_employees`, params: {disabled: true}},
        {url: `/api/search/employee_start_dates`},
        {url: `/api/scheduler_events/truck_services`},
    ];

    apiCall("Loading events data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            events: all[2].data,
            holidays: all[0].data.content,
            birthdays: all[1].data,
            employees: all[3].data,
            startDates: all[4].data,
            truckServices: all[5].data,
        };
        callback(data);
    }, dispatch, getState, false);
};

export const saveFilter = (resource, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/save_scheduler_filter`,
        method: 'PUT',
        data: {resource}
    };
    apiCall("Saving user filter", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getFilters = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/get_scheduler_filter`,
        method: 'GET'
    };
    apiCall("Getting user filters", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const saveUserPreset = (resource, callback) => (dispatch, getState) => {
    const config = {
        url:  resource.id ? `/api/scheduler_presets/${resource.id}` : `/api/scheduler_presets`,
        method: resource.id ? "PUT" : "POST",
        data: {resource}
    };
    apiCall("Saving user preset", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, resource.id && false);
};

export const updateUserPreset = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_presets/${params.id}`,
        method: "PUT",
        data: {
            id: params.id,
            isModal: params.activeModal,
            ...params
        },
    };
    apiCall("Saving user preset", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState,  false);
};

export const getUserPresets = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_presets`,
        method: 'GET'
    };
    apiCall("Getting user presets", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getUserPreset = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_presets/${id}`,
        method: 'GET'
    };
    apiCall("Getting user preset", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const deleteUserPreset = (params, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_presets/${params.id}`,
        method: "DELETE",
        data: {
            id: params.id,
            active_id: params.active_id
        }
    };

    apiCall("Deleting filter", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState);
};

export const loadHolidays = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/holidays`,
        method: 'GET'
    };
    apiCall("Getting holidays", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const loadBirthdays = (callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/birthdays`,
        method: 'GET'
    };
    apiCall("Getting birthdays", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};


export const loadContextData = (clientId, callback) => (dispatch, getState) => {
    const config = [
        {url: `/api/scheduler_event_types`},
        {url: `/api/users/get_scheduler_filter`},
        {url: `/api/search/all_employees`, params: {disabled: true}},
        {url: `/api/clients/${clientId}`},
        {url: `/api/scheduler_presets`}
    ];

    apiCall("Loading data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        const data = {
            schedulerEventTypes: all[0].data,
            employees: all[2].data,
            client: all[3].data,
            filters: all[1].data,
            searchFilters: all[4].data
        };
        callback(data);
    }, dispatch, getState, false);
};


export const loadFilterData = callback => (dispatch, getState) => {
    const config = [
        {url: '/api/service_types'},
        {url: '/api/services/info'},
        {url: '/api/priorities/search?list=yesplease'},
        {url: '/api/equipments/search'},
        {url: '/api/proposal_service_statuses?work_orders=true'},
        {url: '/api/reasons'}
    ];


    apiCall("Loading filter data", config, async config => {
        let actions = config.map(c => axios.request(c));
        const all = await axios.all(actions);
        callback({
            serviceTypes: mapForSelect(all[0].data),
            services: mapForSelect(all[1].data),
            priorities: mapForSelect(all[2].data),
            equipment: mapForSelect(all[3].data),
            proposalServiceStatuses: all[4].data.map(x => ({value: x.code, label: x.label})),
            reasons: mapForSelect(all[5].data),
        });
    }, dispatch, getState, false);
};

export const searchForCustomer = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/customers/name_zip_city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for customers", config, async config => {
        const response = await axios.request(config);
        callback(mapForSelect(response.data));
    }, dispatch, getState, false);
};

export const searchForWorkOrder = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/work_orders/no_search`,
        params: {q: searchText}
    };

    apiCall("Searching for work order", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.work_order_no)));
    }, dispatch, getState, false);
};

export const searchForCity = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/city_search`,
        params: {q: searchText}
    };

    apiCall("Searching for city", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.city)));
    }, dispatch, getState, false);
};

export const searchForZip = (searchText, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/sites/zip_search`,
        params: {q: searchText}
    };

    apiCall("Searching for zip", config, async config => {
        const response = await axios.request(config);
        callback(optionsFor(...response.data.map(x => x.zip)));
    }, dispatch, getState, false);
};

export const syncCalendar = (id, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/clients/${id}/sync_calendar`,
        method: 'POST'
    };
    apiCall("Scheduler refresh", config, async config => {
        await axios.request(config);
        callback && callback();
    }, dispatch, getState);
};


export const toggleSchedulerView = () => {
    return {type: TOGGLE_SCHEDULER_VIEW};
};

export const getEvents = (q, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/search_events`,
        params: {q: q}
    };
    apiCall("Getting events", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const getUserCalendarState = (callback) => (dispatch, getState) => {
    const config = {
        url: "/api/users/get_user_calendar_state",
    };

    apiCall("Getting user calendar state", config, async config => {
        const response = await axios.request(config);
        callback(response.data);
    }, dispatch, getState, false);
};

export const setUserCalendarState = (calendar, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/users/set_user_calendar_state`,
        method: 'PUT',
        data: {calendar}
    };
    apiCall("Setting user calendar state", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};

export const updateEmployeeColor = (employee, callback, errorCallback) => (dispatch, getState) => {
    const config = {
        url: `/api/employees/update_employee_color`,
        method: "put",
        data: {employee}
    };
    apiCall("Update Employee Color", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false, errorCallback);
};

export const loadTotalApproved = (filter, callback) => (dispatch, getState) => {
    const config = {
        url: `/api/scheduler_events/total_approved`,
        params: filter
    }

    apiCall("Loading total approved", config, async config => {
        const response = await axios.request(config);
        callback && callback(response.data);
    }, dispatch, getState, false);
};