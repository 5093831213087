import React from 'react';
import {Modal} from "react-bootstrap";
import DraggableModalDialog from "./DraggableModalDialog";

const ReoccurringEventModal = ({
                                   show,
                                   onHide,
                                   onChangeSingle,
                                   onChangeAll,
                                   onChangeAllFuture,
                                   event,
                                   message = null
                               }) => {
    return (
        <Modal
            bsSize="big"
            animation={false}
            show={show}
            onHide={onHide}
            className={"heightAuto maxHeight90"}
            backdrop={false}
            dialogComponentClass={DraggableModalDialog}
        >
            <Modal.Header closeButton>
                <Modal.Title>Reoccurring Event Change</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={"padding-top-bottom-8"}>
                    {message ? message : "This event is reoccurring. Do you want to change only this event or this and all related future events?"}
                </div>
            </Modal.Body>

            <Modal.Footer className='d-flex align-center'>
                <button type="button" onClick={() => {
                    onChangeSingle(event)
                    onHide();
                }} className="btn btn-success">Change this event only
                </button>
                <button type="button" onClick={() => {
                    onChangeAll(event)
                    onHide();
                }} className="btn btn-success">Change all
                </button>
                <button type="button" onClick={() => {
                    onChangeAllFuture(event)
                    onHide();
                }} className="btn btn-success">Change all future events
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReoccurringEventModal;