import React, {useEffect, useState, useRef} from "react";
import {Button, Col, Row, Well, InputGroup, FormGroup, Tooltip, OverlayTrigger} from "react-bootstrap";
import * as api from "./CrewMessageApi"
import './CrewMessages.css';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    faArrowCircleRight,
    faEdit,
    faExclamationTriangle,
    faTrash,
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InlineEditable from "../InlineEditable";
import {CrewMessageEditor} from './CrewMessagesEditor'
import HazardModal from "./HazardModal";
import createApiService from "../../../common/clientActionsBuilder";
import {ImageLightBox} from './ImageLightBox';
import {MediaComponent} from './MediaComponent';
import {DocAttachment} from '../../../components/docs/DocAttachment'
import MDSpinner from "react-md-spinner";
import linkifyHtml from 'linkifyjs/html';
import {locales} from "../../AppWrapper/contents/constants";
import {clearDeletedHazard, clearGetHazard} from "./actions";

const siteHazardApi = createApiService('site_hazards', 'site_hazard', 'Site Hazard');
const Actions = {...api, ...siteHazardApi, clearDeletedHazard, clearGetHazard}

const isMobile = window.screen.width <= 1024;

const CrewMessages = ({workOrderId, auth, siteId, renderSendArea = true, employees, isCalendarView, hazard, deletedHazard, eventPersonIds, locale, ...props}) => {
    const [messages, setMessages] = useState([]);
    const [editMessage, setEditMessage] = useState(null)
    const [reply, setReply] = useState(null);
    const [isHazardModalOpen, setIsHazardModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null)
    const [isImageModalOpened, setIsImageModalOpened] = useState(false)
    const [uploadedImages, setUploadedImages] = useState([])
    const [uploadedDocs, setUploadedDocs] = useState([])
    const [removeImageId, setRemoveImageId] = useState([])
    const [loading, setLoading] = useState(false)
    const [replyText, setReplyText] = useState(false)

    const message = useRef(null);
    const fileInput = useRef(null)
    const firstUpdate = useRef(true)

    const {loadCrewMessages, saveCrewMessage, deleteCrewMessage, markAsRead, getUserStatus, uploadImages, uploadFiles, removeFile, getSiteHazards, clearDeletedHazard, clearGetHazard} = props.actions;

    const showTranslation = locale === locales.ES;

    useEffect(() => {
        loadCrewMessages(workOrderId, messages => {
            setMessages(messages)
        })
        markAsRead(workOrderId, () => {
            if (auth?.user_id && auth?.client_id) {
                getUserStatus(auth.user_id, auth.client_id)
            }
        });
    }, [workOrderId])

    useEffect(() => {
        if (selectedImage) {
            toggleImageModal(true)
        } else {
            toggleImageModal(false)
        }
    }, [selectedImage])

    useEffect(() => {
        if (!uploadedImages?.length > 0) {
            const fileInput = document.getElementById("inputFile");

            if (fileInput) {
                document.getElementById("inputFile").value = "";
            }
        }
    }, [uploadedImages, uploadedDocs])

    useEffect(() => {
        if (removeImageId?.length > 0) {
            removeFile(removeImageId, () => {
                setRemoveImageId([])
            })
        }
    }, [removeImageId])

    useEffect(() => {
        if (firstUpdate.current && hazard === null) {
            firstUpdate.current = false
            return
        }

        if (hazard && !firstUpdate.current) {
        // if (hazard) {
            getSiteHazards(siteId, siteHazards => {
                if (siteHazards?.length > 0) {
                    const selectedHazard = siteHazards.find(siteHazard => siteHazard.id === hazard.site_hazard_id)
                    let hazardData = {
                        hazard_id: selectedHazard.hazard_id,
                        site_id: siteId,
                        name: selectedHazard.hazard_name,
                        images: selectedHazard.images
                    }
                    onAddHazard(hazardData, false)
                }
            })
        } else {
            return;
        }
    }, [hazard, siteId])

    useEffect(() => {
        if (deletedHazard === null || deletedHazard === undefined) {
            return
        }
        if (deletedHazard) {
            onDeleteHazard(deletedHazard)
        }
    }, [deletedHazard, siteId])

    const toggleImageModal = (value) => {
        setIsImageModalOpened(value)

        if (value !== true) {
            setSelectedImage(null)
        }
    }

    const onSubmit = (id, personIds) => {
        !id && setLoading(true)
        const messageBody = messages.find(m => m.id === id)?.[showTranslation ? "translated_body" : "body"];
        let replyMsg = id ? messageBody : reply
        const imageIds = uploadedImages?.length > 0 && uploadedImages.map(img => img.id)
        const docIds = uploadedDocs?.length > 0 && uploadedDocs.map(doc => doc.id)

        replyMsg = linkifyHtml(replyMsg, {
            defaultProtocol: 'https',
            target: {
                url: '_blank'
            },
        })

        saveCrewMessage({
            id: id,
            body: replyMsg,
            work_order_id: workOrderId,
            images: imageIds || [],
            docs: docIds,
            person_ids: personIds
        }, message => {
            message.images = uploadedImages
            message.docs = uploadedDocs

            setReply(null)
            setReplyText(false)
            setUploadedImages([])
            setUploadedDocs([])
            setEditMessage(null)

            loadCrewMessages(workOrderId, response => {
                setMessages(response);
                !id && setLoading(false);
            })
        })
    }

    const onRemove = (id) => {
        deleteCrewMessage(id, (success) => {
            if (success) {
                setMessages(messages.filter(message => message.id !== id))
            }
        })
    }

    const setValue = (value, id, text) => {
        if (id) {
            const updatedMessages = messages.map(m => m.id === id ? ({...m, [showTranslation ? "translated_body" : "body"]: value}) : ({...m}))
            setMessages(updatedMessages)
        } else {
            setReply(value)
            setReplyText(text)
        }
    }

    const onAddHazard = (resource, shouldAddHazard = true) => {
        let data = {hazard_id: resource.hazard_id, site_id: siteId, images: resource.images}
        const imageIds = resource.images?.length > 0 && resource.images.map(image => image.id)
        if (shouldAddHazard) {
            props.actions.save(data, () => {
                saveCrewMessage({
                    id: null,
                    body: `Created new site hazard: ${resource.name}`,
                    work_order_id: workOrderId,
                    person_ids: personIds,
                    images: imageIds || []
                }, message => {
                    message.images = resource.images
                    setMessages([...messages, message])
                    setReply('');
                    setIsHazardModalOpen(false);
                })
            });
        } else {
            saveCrewMessage({
                id: null,
                body: `Created new site hazard: ${resource.name}`,
                work_order_id: workOrderId,
                person_ids: personIds,
                images: imageIds || []
            }, message => {
                message.images = resource.images
                setMessages([...messages, message])
                setReply('');
                setIsHazardModalOpen(false);
            })
        }
        clearDeletedHazard();
        clearGetHazard();
    }

    const onDeleteHazard = (resource) => {
        saveCrewMessage({
            id: null,
            body: `Deleted site hazard: ${resource.name}`,
            work_order_id: workOrderId,
            person_ids: personIds
        }, message => {
            setMessages([...messages, message])
            setReply('');
            setIsHazardModalOpen(false);
            clearDeletedHazard();
            clearGetHazard();
        });
    }

    const openFileDialog = e => {
        e.preventDefault();
        fileInput.current.click();
    }

    const renderTooltipWithName = (name) => (
        <Tooltip id="tooltip">
            <strong>{name}</strong>
        </Tooltip>
    )

    const editorContent = () => {
        return !(replyText || uploadedImages?.length > 0 || uploadedDocs?.length > 0);
    }

    const removeMessageFile = (msgId, id, type) => {
        removeFile(id, type, () => {
            if (msgId) {
                let newMessages = []
                const index = messages.findIndex(m => m.id === msgId)
                const message = messages[index]
                message[type] = messages[index][type].filter(file => file.id !== id)
                newMessages = [...messages.filter(m => m.id !== msgId), message]
                newMessages = newMessages.sort((a, b) => a.id - b.id)
                setMessages(newMessages)
            } else {
                let newFiles = []

                if (type === 'images') {
                    newFiles = uploadedImages.filter(img => img.id !== id)
                    setUploadedImages(newFiles)
                } else if (type === 'docs') {
                    newFiles = uploadedDocs.filter(doc => doc.id !== id)
                    setUploadedDocs(newFiles)
                }
            }
        })
    }

    const onSelectImage = src => {
        setSelectedImage(src)
    }

    let personIds = []
    let messagePersonIds = []

    if (messages?.length > 0) {
        messagePersonIds = messages.map(m => m.person_id)
        personIds = messagePersonIds.concat(eventPersonIds).filter((m, i, arr) => arr.indexOf(m) === i)
    } else {
        personIds = (eventPersonIds).filter((m, i, arr) => arr.indexOf(m) === i)
    }

    const wellHeight = isCalendarView && messages?.length === 0 && 'fit-content'

    return (<>
        <Well className="pb-26" style={{height: wellHeight}}>
            <Row>
                <Col xs={12}
                     className={`${!isCalendarView && 'message-container-content'} messages-container ${isMobile && 'no-padding'}`}>
                    {messages.map(({id, is_author, body, translated_body, created_at, initials, person_id, images, docs}) => {
                            const employee = employees.find(e => e.value === person_id)
                            const background = employee ? employee.color : '#3a87ad'
                            const employeeInitials = employee && employee.initials ? employee.initials : initials
                            const index = messages.findIndex(message => message.id === id)
                            const messageBody = showTranslation ? translated_body : body;
                            return (
                                <div className={`message-item ${isMobile && 'padding5'}`} key={id}>
                                    <div
                                        className={`inline-container ${is_author ? 'orientation-right' : 'orientation-left'}`}>
                                        <OverlayTrigger placement="top" overlay={renderTooltipWithName(employee?.label)}>
                                            <div className='avatar' style={{background}}>{employeeInitials}</div>
                                        </OverlayTrigger>
                                        <div className='message-content' ref={message}>
                                            <div className='message-content-date'>{moment(created_at).format('llll')}</div>
                                            <InlineEditable
                                                editMessage={index === editMessage}
                                                editable={false}
                                                closeEditable={() => {
                                                    onSubmit(id)
                                                }}
                                                form={() =>
                                                    <CrewMessageEditor
                                                        setValue={setValue}
                                                        messageId={id}
                                                        reply={messageBody}
                                                        locale={locale}
                                                    />
                                                }
                                                value={messageBody && <div dangerouslySetInnerHTML={{__html: messageBody}}/>}
                                            />
                                            {(images?.length > 0 || docs?.length > 0) && <hr/>}
                                            <div className='flex-wrap'>
                                                {images?.length > 0 && images.map(img => (
                                                    <MediaComponent imageId={img.id} imageSrcMini={img.mini} messageId={id}
                                                                    imageSrcOriginal={img.original}
                                                                    is_author={is_author} onSelectImage={onSelectImage}
                                                                    isCalendarView={isCalendarView}
                                                                    toggleImageModal={toggleImageModal}
                                                                    removeMessageFile={removeMessageFile}/>
                                                ))}
                                                {docs?.length > 0 && docs.map(doc => <DocAttachment key={doc.id}
                                                                                                    messageId={doc.crew_message_id}
                                                                                                    file={doc}
                                                                                                    allowRemove={is_author}
                                                                                                    isCrewMessage={true}
                                                                                                    deleteDoc={docId => removeMessageFile(id, docId, "docs")}
                                                />)}
                                            </div>


                                            {is_author && !(
                                                messages[index].body.includes('Created new site hazard: ') ||
                                                messages[index].body.includes('Deleted site hazard: ')
                                                ) &&
                                            <div className='message-content-edit'>
                                                {editMessage === index &&
                                                <FontAwesomeIcon icon={faUpload} onClick={e => openFileDialog(e)}
                                                                 className="mr-7 font12 pointer"/>}
                                                <FontAwesomeIcon icon={faEdit} className="mr-7 font12 pointer"
                                                                 onClick={() => setEditMessage(index)}/>
                                                <FontAwesomeIcon icon={faTrash} className="font12 pointer"
                                                                 onClick={() => onRemove(id)}/>
                                            </div>}
                                        </div>
                                    </div>
                                </div>)
                        }
                    )}
                </Col>
            </Row>
            <Row className='message-send-area-wrapper'>
                <Col xs={12}>
                    <FormGroup>
                        <InputGroup style={{position: 'relative', display: 'block', width: '100%'}}>
                            <CrewMessageEditor
                                setValue={setValue}
                                reply={reply}
                            />
                            <div className='message-image-wrapper'>
                                {!editMessage && uploadedImages?.length > 0 && uploadedImages.map(img => (
                                    <MediaComponent imageId={img.id} imageSrcMini={img.mini}
                                                    imageSrcOriginal={img.original} messageId={null}
                                                    removeMessageFile={removeMessageFile}
                                                    onSelectImage={onSelectImage} toggleImageModal={toggleImageModal}
                                                    isCalendarView={false}
                                                    is_author={true}/>
                                ))}
                                {!editMessage && uploadedDocs && uploadedDocs.length > 0 && uploadedDocs.map(doc =>
                                    <DocAttachment
                                        key={doc.id}
                                        isReplyMode={true}
                                        file={doc} isCrewMessage={true}
                                        deleteDoc={docId => removeMessageFile(null, docId, "docs")}
                                    />)}
                            </div>
                            <div className="message-send-container">
                                <div className='crew-message-wrapper'>
                                    <div className='crew-message-notification'>
                                        <input
                                            id="inputFile"
                                            ref={fileInput}
                                            onChange={e => {
                                                const fileName = e.target.files[0].name
                                                const extension = e.target.files[0].type;
                                                const isImageType = fileName.includes('.heic') || fileName.includes('.heif') || extension.includes("image/png") || extension.includes("image/jpeg")
                                                    || extension.includes("image/jpg") || extension.includes("image/heic") || extension.includes("image/heif")

                                                if (isImageType) {
                                                    uploadImages(e.target.files, null, img => {
                                                        if (editMessage) {
                                                            let newMessages
                                                            const editedMessage = messages[editMessage]
                                                            editedMessage.images = [...messages[editMessage].images, img]
                                                            newMessages = [...messages.filter(m => m.id !== editedMessage.id), editedMessage]
                                                            newMessages = newMessages.sort((a, b) => a.id - b.id)
                                                            setMessages(newMessages)
                                                        }
                                                        setUploadedImages([...uploadedImages, img])
                                                    })
                                                } else {
                                                    uploadFiles(e.target.files, 1, file => {
                                                        if (editMessage) {
                                                            let newMessages
                                                            const editedMessage = messages[editMessage]
                                                            editedMessage.docs = [...messages[editMessage].docs, file]
                                                            newMessages = [...messages.filter(m => m.id !== editedMessage.id), editedMessage]
                                                            newMessages = newMessages.sort((a, b) => a.id - b.id)
                                                            setMessages(newMessages)
                                                        }
                                                        setUploadedDocs([...uploadedDocs, file])
                                                    })
                                                }
                                            }}
                                            type='file'
                                            style={{visibility: 'hidden', height: 0}}/>
                                        <Button disabled={editMessage} className="message-send-btn"
                                                onClick={e => openFileDialog(e)}>
                                            <FontAwesomeIcon icon={faUpload} className="message-send-icon"/>
                                        </Button>
                                        <Button disabled={editMessage} className="message-send-btn"
                                                onClick={() => setIsHazardModalOpen(true)}>
                                            <FontAwesomeIcon icon={faExclamationTriangle}
                                                             className="message-send-icon"/>
                                        </Button>
                                        {loading ?
                                            <div className="d-flex message-send-icon">
                                                <MDSpinner size={12} className='mr-7'/>
                                                <span className='font11'>Sending notification</span>
                                            </div> :
                                            <Button disabled={editorContent()} className="message-send-btn"
                                                    onClick={() => onSubmit(null, personIds)}>
                                                <FontAwesomeIcon icon={faArrowCircleRight}
                                                                 className="message-send-icon"/>
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        </Well>
        <HazardModal show={isHazardModalOpen} onHide={() => setIsHazardModalOpen(false)} onSave={onAddHazard}/>
        <ImageLightBox images={[selectedImage]}
                       isImageModalOpened={isImageModalOpened}
                       toggleImageModal={toggleImageModal}/>
    </>)
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Actions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        hazard: state.crewWorkOrders.hazard,
        deletedHazard: state.crewWorkOrders.deletedHazard,
        locale: state.userStatus.userStatus.locale,
        state
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrewMessages);