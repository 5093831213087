import React from 'react';
import classNames from "classnames";
import PropTypes from "prop-types";
import Dollars from "../../../../components/Dollars";
import {dollarsFormatter} from "../../../../components/BootStrapTableCellFormatters";
import moment from "moment";

const VersionHistoryDetails = ({VHDetails}) => {
    const {additional_info, version_details} = VHDetails
    const signature = version_details[0].transition ===  "signature/proposal" ? JSON.parse(version_details[0].new_data) : {}

    const formatPeriod = (event_date_from, event_date_to, event_all_day) => {
        if (event_all_day) {
            event_date_from = moment(event_date_from).format('YYYY-MM-DD');
            event_date_to = moment(event_date_to).format('YYYY-MM-DD');
        }

        return event_date_from && event_date_to && <span>
            <p className='no-margin inline-block'>
                {moment(event_date_from).format('dddd, MMMM DD')}
            </p>
            <p className='inline-block timeScheduler ml-12'>
                <span>
                    {moment(event_date_from).format('LT')} {event_date_to && `- ${moment(event_date_to).format('LT')}`}
                </span>
            </p>
        </span>
    };

    return (
        <div className='vh'>
            {additional_info.work_order_no
                && <div className={classNames(
                    'vh__event',
                    {'vh__event--on-scheduled': additional_info.assignee},
                    {'vh__event--on-wo': additional_info.work_order_no},
                    {'vh__event--on-invoiced': additional_info.invoice_no}
                )}>
                    {additional_info.assignee
                        && <p className='vh__event--assignee'>
                            <strong>Assignee</strong>: {additional_info.assignee.length > 1 ? additional_info.assignee : "No assignee"}
                        </p>
                    }
                    <p className='vh__event--work-order'>
                        <strong>Work Order</strong>: #{additional_info.work_order_no}
                    </p>
                    <p className='vh__event--proposal'><
                        strong>Proposal</strong>: #{additional_info.proposal_no}</p>
                    {additional_info.invoice_no
                        && <p className='vh__event--invoice'>
                            <strong>Invoice</strong>: #{additional_info.invoice_no}
                        </p>
                    }
                </div>
            }

            {version_details[0].transition === 'signature/proposal' ?
                <div>
                    <img src={signature.signature} className="full-width"/>
                    {signature.first_name && <p className='font14'><strong>Full Name:</strong> {signature.first_name}{" "}{signature.last_name}</p>}
                    {signature.ip && <p className='font14'><strong>IP address:</strong> {signature.ip}</p>}
                </div>
            :
            <div className={classNames('vh__records')}>
                {version_details.map((vd, id) => {
                    const is_not_related_to_ps = 'prev_data' in vd
                    return (
                        <div className={`vh-row ${is_not_related_to_ps ? 'vh-row--full' : ""}`} key={id}>
                            {
                                is_not_related_to_ps
                                    ? <div>
                                        <p><s>{vd.prev_data}</s></p>
                                        <p>{vd.new_data}</p>
                                    </div>
                                    : <>
                                        <p className='vh-row--idx'>#{vd.order_number_in_proposal || vd.service_number}</p>
                                        <p className='vh-row--service-name'>{vd.name}</p>
                                        <div className='vh-row--assets-ids align-center'>
                                            {vd.new_assets_ids
                                                ? <>
                                                    <p><s>{vd.prev_assets_ids.map(asset_id => asset_id).join(', ')}</s></p>
                                                    <p>{vd.new_assets_ids.map(asset_id => asset_id).join(', ')}</p></>
                                                : <p>{vd.prev_assets_ids.map(asset_id => asset_id).join(', ')}</p>
                                            }
                                        </div>
                                        <p className='vh-row--man-hours'>{vd.man_hours}hr</p>
                                        <div className='vh-row--wo--date align-center'>
                                            <p className='prev-wo-date'><s>{formatPeriod(vd.prev_wo_date_from, vd.prev_wo_date_to, vd.prev_wo_all_day)}</s></p>
                                            <p>{formatPeriod(vd.wo_date_from, vd.wo_date_to, vd.wo_all_day)}</p>
                                        </div>
                                        <div className='vh-row--priorities align-center'>
                                            {vd.new_priorities
                                                ? <>
                                                <p><s>{vd.prev_priorities.map(p => p).join(', ')}</s></p>
                                                    <p>{vd.new_priorities.map(p => p).join(', ')}</p></>
                                                : <p>{vd.prev_priorities.map(p => p).join(', ')}</p>
                                            }
                                        </div>
                                        <div className='vh-row--status-transition align-center'>
                                            <p><s>{vd.prev_status}</s></p>
                                            <p>{vd.new_status}</p>
                                        </div>
                                        <p className='vh-row--service-total'><Dollars amount={vd.service_total}/></p>
                                        {(vd.prev_promised_dates || vd.new_promised_dates) &&
                                            <div className='vh-row--promised_dates align-center'>
                                                {
                                                    <>
                                                        <p><s>{vd.prev_promised_dates}</s></p>
                                                        <p>{vd.new_promised_dates}</p>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </>
                            }
                        </div>
                    )
                })}
            </div>}
        </div>
    );
};

VersionHistoryDetails.propTypes = {
    VHDetails: PropTypes.object.isRequired
}

export default VersionHistoryDetails;